import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ButtonLinkComponent } from './button-link/button-link.component';

@NgModule({
  declarations: [ButtonLinkComponent],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
  ],
  exports: [
    ButtonLinkComponent,
  ]
})
export class FabModule { }
