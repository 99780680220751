import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Animation, AnimationController, IonFab, IonFabButton } from '@ionic/angular';

@Component({
  standalone: false,
  selector: 'kc-fab-button-link',
  templateUrl: './button-link.component.html',
  styleUrls: ['./button-link.component.scss'],
})
export class ButtonLinkComponent  implements OnInit, OnDestroy {
  @ViewChild(IonFabButton, { read: ElementRef }) fabButton!: ElementRef<HTMLIonFabElement>;
  @Input() route: (string | undefined)[] = [];
  @Input() icon_name: string = 'add';

  private animation!: Animation;

  constructor(private animationCtrl: AnimationController) { }

  ngOnInit() {}

  ngAfterViewInit() {
    const animation: Animation = this.animationCtrl
      .create()
      .addElement(this.fabButton.nativeElement)
      .duration(2000)
      .easing('ease')
      .iterations(Infinity)
      .keyframes([
        { offset: 0.7, transform: 'translateY(0%)' },
        { offset: 0.8, transform: 'translateY(-15%)' },
        { offset: 0.9, transform: 'translateY(0%)' },
        { offset: 0.95, transform: 'translateY(-7%)' },
        { offset: 0.97, transform: 'translateY(0%)' },
        { offset: 0.99, transform: 'translateY(-3%)' },
        { offset: 1, transform: 'translateY(0%)' },
      ])

    this.animation = animation
    this.animation.play();
  }

  ngOnDestroy() {
    this.animation.destroy();
  }

}
