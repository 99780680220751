import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthService, CapacitorService, FirebaseService, PushNotificationService } from './services';
import { UserInterface, RolesEnum, ProfileRoleInterface } from '@kiddy-cash/common';
import { AlertController, ModalController } from '@ionic/angular';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { DeviceInfo } from '@capacitor/device';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import { register } from 'swiper/element/bundle';
import { UpdateComponent } from './components/mobile-app/update/update.component';
import { SafeArea } from 'capacitor-plugin-safe-area';

register();

@Component({
  standalone: false,
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  RolesEnum = RolesEnum;
  user: UserInterface | null | undefined;
  isLoggedIn: boolean = false;
  hasRoles: boolean = true;
  hasKiddyRole: boolean = false;
  hasCustodianRole: boolean = false;
  staticBaseUrl: string =  environment.staticBaseUrl;
  deviceInfo: DeviceInfo | undefined;
  statusBarHeight: number = 0;
  private modal!: HTMLIonModalElement;

  constructor(
    private authService: AuthService, 
    private pushNotificationService: PushNotificationService,
    private router: Router,
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private firebaseService: FirebaseService,
    private capacitorService: CapacitorService,
    private location: Location,
    ) {}

  async ngOnInit() {
    this.authService._$user.subscribe((user: UserInterface | null | undefined) => {
      this.user = user;
      user ? this.isLoggedIn = true : this.isLoggedIn = false;
      if(user){
        const profile = user.profile;
        const profile_roles = profile.profile_roles || [];

        profile_roles.length ? this.hasRoles = true : this.hasRoles = false

        const hasKiddyRole = (profile_role: ProfileRoleInterface) => profile_role.role.title === RolesEnum.Kiddy;
        const hasCustodianRole = (profile_role: ProfileRoleInterface) => profile_role.role.title === RolesEnum.Custodian;

        profile_roles.find(hasKiddyRole) ? this.hasKiddyRole = true : this.hasKiddyRole = false
        profile_roles.find(hasCustodianRole) ? this.hasCustodianRole = true : this.hasCustodianRole = false
      } else {
        this.hasRoles = false
        this.hasKiddyRole = false
        this.hasCustodianRole = false
      }
    });
    this.pushNotificationService.setUp()
    this.firebaseService.setUp()

    await this.setUpModal();

    this.capacitorService._$deviceInfo.subscribe(async (deviceInfo: DeviceInfo | undefined) => {
      this.deviceInfo = deviceInfo;
      if(deviceInfo?.platform === 'ios' || deviceInfo?.platform === 'android') {
        if(await this.isPlayStoreAppUpdateAvailable()) this.askToUpdateApp(); // Play Store Updates
      }
    });

    await SafeArea.getStatusBarHeight().then(async ({ statusBarHeight }) => {
      this.statusBarHeight = statusBarHeight;
    });

    await SafeArea.addListener('safeAreaChanged', async data => {
      const { insets } = data;
      for (const [key, value] of Object.entries(insets)) {
        document.documentElement.style.setProperty(
          `--safe-area-inset-${key}`,
          `${value}px`,
        );
      }
    });

    await App.addListener('appUrlOpen', (data: URLOpenListenerEvent) => {
      const url = new URL(data.url);
      const path = url.pathname;
      const search = url.search;
      search ? this.router.navigateByUrl(path + search) : this.router.navigateByUrl(path);
    });

    await App.addListener('backButton', ({ canGoBack }) => {
      if(canGoBack){
        this.location.back()
      } else {
        App.exitApp();
      }
    });
  }

  private async setUpModal() {
    this.modal = await this.modalCtrl.create({
      component: UpdateComponent,
      canDismiss: false,
      cssClass: 'width-100-height-100'
    });

    return this.modal
  }

  isPlayStoreAppUpdateAvailable = async () : Promise<boolean> => {
    const result = await AppUpdate.getAppUpdateInfo();
    return result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE;
  };

  async askToUpdateApp() {
    this.openModal()
  }

  async logout() {
    const alert = await this.alertController.create({
      header: 'Logout',
      subHeader: 'Are you sure?',
      message: 'Logging out means you will have to log in again!',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => { 
            
          }
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: async () => { 
            await this.pushNotificationService.unregisterToken()
            await this.authService.logout();
            this.router.navigateByUrl('/')
          }
        }
      ]
    });

    await alert.present();
  }

  openModal() {
    if(this.modal?.isOpen) return;
    this.modal.canDismiss = false;
    this.modal.present();
  }

  dismissUpdateModal() {
    if(!this.modal?.isOpen) return;
    this.modal.dismiss();
  }

  openHelpCenter() {
    window.open("https://support.kiddy.cash/", "KiddyCashSupport");
  }

}
