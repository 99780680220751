import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HeaderModule } from "src/app/components/header/header.module";
import { SelectModule } from "src/app/components/select/select.module";
import { UserModule } from "src/app/components/user/user.module";
import { ChildInformationModule } from "src/app/components/child-information/child-information.module";
import { ManagerInformationModule } from "src/app/components/manager-information/manager-information.module";
import { ProfileFormModule } from "src/app/components/profile-form/profile-form.module";
import { NoAccessModule } from "src/app/components/no-access/no-access.module";
import { NotFoundModule } from "src/app/components/not-found/not-found.module";
import { ServerErrorModule } from "src/app/components/server-error/server-error.module";
import { AccountModule } from "src/app/components/account/account.module";
import { TaskModule } from "src/app/components/task/task.module";
import { AllowanceModule } from "src/app/components/allowance/allowance.module";
import { LoanModule } from "src/app/components/loan/loan.module";
import { ActivityModule } from "src/app/components/activity/activity.module";
import { TransactionModule } from "src/app/components/transaction/transaction.module";
import { GoalModule } from "src/app/components/goal/goal.module";
import { InvestmentModule } from "src/app/components/investment/investment.module";
import { RegistrationFormModule } from "src/app/components/registration-form/registration-form.module";
import { BreadcrumbModule } from "src/app/components/breadcrumb/breadcrumb.module";
import { PaymentCardModule } from "src/app/components/payment-card/payment-card.module";
import { ChartModule } from "src/app/components/chart/chart.module";
import { PipesModule } from "src/app/core/pipes/pipes.module";
import { MaskitoDirective, MaskitoPipe } from '@maskito/angular';
import { MobileAppModule } from "src/app/components/mobile-app/mobile-app.module";
import { IntegrationModule } from "src/app/components/integration/integration.module";
import { FamilyModule } from "src/app/components/family/family.module";
import { TransactionCodeModule } from "src/app/components/transaction-code/transaction-code.module";
import { SchoolModule } from "src/app/components/school/school.module";
import { SchoolClassModule } from "src/app/components/school-class/school-class.module";
import { BusinessModule } from "src/app/components/business/business.module";
import { KiddyBusinessManagerInformationModule } from "src/app/components/kiddy-business-manager-information/kiddy-business-manager-information.module";
import { DocumentModule } from "src/app/components/document/document.module";
import { FabModule } from "src/app/components/fab/fab.module";

@NgModule({
  imports: [
    CommonModule, 
    RouterModule,
    MaskitoDirective,
    MaskitoPipe, 
  ],
  exports: [
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderModule,
    UserModule,
    AccountModule,
    SelectModule,
    ChildInformationModule,
    ManagerInformationModule,
    ProfileFormModule,
    NotFoundModule,
    NoAccessModule,
    ServerErrorModule,
    ActivityModule,
    AllowanceModule,
    GoalModule,
    FamilyModule,
    InvestmentModule,
    LoanModule,
    TaskModule,
    TransactionModule,
    RegistrationFormModule,
    BreadcrumbModule,
    ChartModule,
    PipesModule,
    PaymentCardModule,
    MaskitoDirective, 
    MaskitoPipe, 
    MobileAppModule,
    IntegrationModule,
    TransactionCodeModule,
    SchoolModule,
    SchoolClassModule,
    BusinessModule,
    KiddyBusinessManagerInformationModule,
    DocumentModule,
    FabModule,
  ],
  providers: [DatePipe],
})
export class SharedModule {}
