import { Component, OnInit, ViewChild } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { UserInterface } from '@kiddy-cash/common';
import { AuthService, CapacitorService, LastActiveService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { NativeBiometric, Credentials } from "@capgo/capacitor-native-biometric";
import { AppInfo } from '@capacitor/app';
import { Device, DeviceId, DeviceInfo } from '@capacitor/device';
import { Router } from '@angular/router';
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js'

@Component({
  standalone: false,
  selector: 'kc-user-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.scss'],
})
export class UserPinComponent implements OnInit {
  @ViewChild('pinInput') input!: IonInput;
  pin: string = '';
  user: UserInterface | null | undefined;
  lastpin: string | undefined;
  pinValidated: boolean = false;
  pinProcessing: boolean = false;
  pinProcessingMessage: string = '';
  pinProcessingStatus: string = '';
  verified: boolean = false;

  staticBaseUrl: string =  environment.staticBaseUrl;
  appInfo!: AppInfo;
  deviceInfo!: DeviceInfo;
  isNativeBiometricAvailable: boolean = false

  constructor(
    private authService: AuthService, 
    private lastActivityService: LastActiveService,
    private capacitorService: CapacitorService,
    private router: Router,
  ) {

  }

  async ngOnInit() {
    this.authService._$user.subscribe((user: UserInterface | null | undefined) => {
      this.user = user;
    });
    this.lastActivityService._$pin.subscribe(pin => {
      if(pin) this.lastpin = pin;
    })
    this.lastActivityService._$pinValidated.subscribe(pinValidated => {
      this.pinValidated = pinValidated;
      if(this.pinValidated && this.verified) this.setBioAuth()
    })
    this.lastActivityService._$pinProcessing.subscribe(pinProcessing => {
      this.pinProcessing = pinProcessing;
    })
    this.lastActivityService._$pinProcessingMessage.subscribe(pinProcessingMessage => {
      this.pinProcessingMessage = pinProcessingMessage;
    })
    this.lastActivityService._$pinProcessingStatus.subscribe(pinProcessingStatus => {
      this.pinProcessingStatus = pinProcessingStatus;
    })

    this.capacitorService._$appInfo.subscribe(async (appInfo: AppInfo | undefined) => {
      if(appInfo) this.appInfo = appInfo;
    });

    this.capacitorService._$deviceInfo.subscribe(async (deviceInfo: DeviceInfo | undefined) => {
      if(deviceInfo) this.deviceInfo = deviceInfo;
      if(deviceInfo?.platform === 'ios' || deviceInfo?.platform === 'android') {
        this.isNativeBiometricAvailable = (await NativeBiometric.isAvailable()).isAvailable;
        // un-comment below line to perform Bio Auth immediately if available
        // if(this.isNativeBiometricAvailable) await this.performBiometricVerification();
      }
    });
  }

  ionViewDidEnter() {
    // this.input.setFocus();
  }

  inputChange(event: any) {
    if(this.pin.length >= 4) return;
    this.pin = event.detail.value;
    if(this.pin.length === 4) this.processPin();
  }

  clickDigit(digit: number) {
    if(this.pin.length >= 4) return;
    this.pin = this.pin + digit;
    this.input.value = this.pin;
    if(this.pin.length === 4) this.processPin();
  }

  deleteLastDigit() {
    if(!this.pin.length) return;
    this.pin = this.pin.slice(0, -1);
    this.input.value = this.pin
  }

  async processPin() {
    this.lastActivityService.setPin(this.pin);
    this.pin = ''
    this.input.value = ''
  }

  async bioAuth() {
    if(this.isNativeBiometricAvailable){
      await this.performBiometricVerification();
    } else {
      this.pinProcessingStatus = 'danger';
      this.pinProcessingMessage = 'Biometrics Not Supported on Device';
    }
  }

  async performBiometricVerification(){
    const result = await NativeBiometric.isAvailable();
  
    if(!result.isAvailable) return;

    this.verified = await NativeBiometric.verifyIdentity({
      reason: "For easy Pin Validation",
      title: "Biometrics Validation",
      subtitle: "Add your biometrics",
      description: "By using your fingerprint or face ID you can quickly validate your Pin",
    })
      .then(() => true)
      .catch(() => false);

    if(!this.verified) return;

    const credentials: Credentials = await this.getBioAuth();
    const storedPin = credentials.password

    if(!storedPin){
      this.pinProcessingStatus = 'info';
      this.pinProcessingMessage = 'Enter your Pin to Confirm Biometrics';
    } else {
      this.pin = storedPin;
      this.processPin();  
    }
  }

  async getBioAuth(): Promise<Credentials> {
    // Get user's credentials
    return await NativeBiometric.getCredentials({
      server: this.appInfo.id,
    });
  }

  async setBioAuth() {
    // Save user's credentials
    const deviceID: DeviceId = await Device.getId();
    await NativeBiometric.setCredentials({
      username: ( this.user?.phone ? this.user?.phone : this.user?.email ) || deviceID.identifier,
      password: this.lastpin || '0000',
      server: this.appInfo.id,
    }).then();
  }

  async deleteBioAuth() {
    // Delete user's credentials
    await NativeBiometric.deleteCredentials({
      server: this.appInfo.id,
    }).then();
  }

  async forgotPin() {
    await this.authService.logout();
    this.router.navigateByUrl('/forgot-pin');
  }

  public formatInternationalPhoneNumber(phone: string | undefined){
    return parsePhoneNumber(phone as string)?.formatInternational()
  }

}
